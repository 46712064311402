export const About = () => {

  return (
    <section className='about' id='about'>
      <div className="about-img">
        <div className="hexagon-about">
          <img src='/images/eu2.png' alt='me2' />
        </div>
      </div>
      <div className="about-content">
        <h2 className="heading">About <span>Me</span></h2>
        <h3>Extensive Experience</h3>
        <p>
          {/* I'm a Full Stack Developer with over 20 years of experience. I have a demonstrated history of building scalable web applications for diverse industries. I have increased app performance by 40% through optimized code and efficient database querying. I have also implemented robust security practices, reducing system vulnerabilities by 70%. Additionally, I emphasize user experience and consistently deliver projects ahead of schedule */}
          Over 20 years of Enterprise Platform engineering and development, IT solutions and product management
        </p>
        <h3>Proven Skills</h3>
        <p>
          Successful product lifecycle management, development of go-to-market strategies, and effective cross-functional team collaboration
        </p>
        <h3>Technical Proficiency</h3>
        <p>
          Expertise in Technical Solution Management, System Administration, and Full Stack Enterprise Applications and Platforms development
        </p>
        <h3>Diverse Roles</h3>
        <p>
          Held various positions from E-Commerce Platform Engineer to Technical Solution Manager, showcasing versatility and leadership in IT projects
        </p>
      </div>
    </section>
  )
}